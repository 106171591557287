import React, { useState, useEffect } from "react";
import { Container, Navbar } from "react-bootstrap";
import css from "./header.module.css";
import MenuMobile from "./menuMobile/MenuMobile";
import ModalFaleConnosco from "../../modal/ModalFaleConnosco";
import { Link } from "react-router-dom";
import { ReactComponent as Hamburger } from "../../../assets/icons/menu/menu.svg";
import { ReactComponent as Contact } from "../../../assets/icons/menu/falar_agora.svg";
import { ReactComponent as Ligar } from "../../../assets/icons/redes/liga.svg";
import MenuBottom from "./menuBottom/MenuBottom";
import useMediaQuery from "../../hooks/useMediaQuery ";
import { CSSTransition } from "react-transition-group";
import { useLocation } from "react-router";
import AuthContext from "../../hooks/AuthContext";
import usePontosVenda from "../../hooks/usePontosVenda";
import TradutorHome from "./menuDesktop/TradutorHome";

const logo = "/assets/img/logo.webp";

export default function TopNav({ infoJson, allinfoText }) {
  const location = useLocation();
  const nodeRef = React.useRef(null);
  const { state } = React.useContext(AuthContext);

  const pontosVenda = usePontosVenda();
  const [disabled, setDisabled] = useState(false);
  const ismobile = useMediaQuery("(max-width: 991px)");
  const [noBlur, setNoBlur] = useState(
    `${infoJson.Layout.Transparente ? css.bgNoBlur : null}`
  );
  const [modalFaleConnosco, setModalFaleConnosco] = useState(false);
  const [show, setShow] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [addClass, setAddClass] = useState(
    location.pathname === "/" && infoJson.Layout.Transparente
      ? "navbar-scroll-custom-visivel"
      : ""
  );
  const [addClassIn, setAddClassIn] = useState(
    location.pathname === "/" && infoJson.Layout.Transparente ? "" : css.logoIn
  );
  const [addClassOut, setAddClassOut] = useState(
    location.pathname === "/" && infoJson.Layout.Transparente ? css.logoOut : ""
  );

  useEffect(() => {
    location.pathname === "/" && infoJson.Layout.Transparente
      ? setAddClassOut(css.logoOut)
      : setAddClassOut("");
    location.pathname === "/" && infoJson.Layout.Transparente
      ? setAddClassIn("")
      : setAddClassIn(css.logoIn);
    location.pathname === "/" && infoJson.Layout.Transparente
      ? setAddClass("navbar-scroll-custom-visivel")
      : setAddClass("");
  }, [location.pathname, infoJson.Layout.Transparente]);

  useEffect(() => {
    //bloqueia o body
    //para não haver scroll na pagina
    modalFaleConnosco
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "visible");
  }, [modalFaleConnosco]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  const handleClose = () => setShow(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > lastScrollTop && offset > 20) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
    if (location.pathname === "/" && infoJson.Layout.Transparente) {
      if (offset > 100) {
        setNoBlur("");
        setAddClass("");
        setAddClassIn(css.logoIn);
        setAddClassOut("");
      } else {
        setAddClass("navbar-scroll-custom-visivel");
        setAddClassIn("");
        setNoBlur(`${css.bgNoBlur}`);
        setAddClassOut(css.logoOut);
      }
    }

    setLastScrollTop(offset);
  };

  return (
    <>
      {pontosVenda.length !== 0 && (
        <MenuMobile
          allinfo={state.infoPrincipal[0]}
          show={show}
          onHide={handleClose}
          infoJson={infoJson}
          allinfoText={allinfoText}
        />
      )}

      <Navbar
        className={
          isScroll
            ? ` ${css.cor_fundo_claro} corFundoClaro ${css.navbarScrollCustom} ${addClass} ${noBlur}`
            : ` ${css.cor_fundo_claro} corFundoClaro ${addClass} ${noBlur}`
        }
        fixed="top"
        expand="lg"
      >
        <Container fluid="xxxl">
          <div
            className={`menuMobile d-lg-none  ${css.boxMenu}`}
            onClick={() => setShow(true)}
          >
            <Hamburger className={`${css.hamburger} p-0`} />
            Menu
          </div>
          {infoJson.Layout.Transparente && location.pathname === "/" ? (
            <Navbar.Brand
              as={Link}
              to="/"
              className={` ${addClassIn} logoTranparent `}
            >
              <img
                src={logo}
                className={` ${css.navbarBrandAlt}`}
                alt={infoJson.Stand.Nome}
              />
            </Navbar.Brand>
          ) : (
            <></>
          )}

          <Navbar.Brand
            as={Link}
            to="/"
            className={`${addClassOut} ${css.navbarBrand} `}
          >
            <img
              src={logo}
              className={`${css.logo_topo2} ${css.navbarBrandAlt}`}
              alt={infoJson.Stand.Nome}
            />
          </Navbar.Brand>

          {state?.infoPrincipal.length > 0 &&
            state?.infoPrincipal[0].CustoChamadaTelemovel && (
              <div
                className={`align-items-end justify-content-end navbar-collapse flex-column px-4 d-none d-lg-flex `}
              >
                <div className={`btnPesquisa  ${css.btnLigar}`}>
                  <Ligar height="22px" width="22px" stroke="#fff" />
                  Linha directa {state?.infoPrincipal[0].Telemovel}
                </div>
                <div style={{ width: 240 }}>
                  {state?.infoPrincipal[0].CustoChamadaTelemovel}
                </div>
              </div>
            )}
          <div
            className={`menuMobileCantact d-lg-none ${css.boxMenu} ${
              modalFaleConnosco ? "active" : ""
            }`}
            onClick={() => setModalFaleConnosco(!modalFaleConnosco)}
          >
            <Contact height={"25px"} width={"25px"} className=" p-0 mb-2 " />
            Falar agora
          </div>

          <div
            className={`menuMobile  d-none d-lg-flex ${css.boxMenu}  px-2 py-1`}
            onClick={() => setShow(true)}
          >
            <Hamburger className={`${css.hamburger} p-0`} />
            Menu
          </div>
          {infoJson.Layout.Tradutor && !ismobile && <TradutorHome />}
        </Container>
      </Navbar>

      <CSSTransition
        nodeRef={nodeRef}
        in={pontosVenda.length !== 0 && modalFaleConnosco && ismobile}
        unmountOnExit
        timeout={100}
        classNames={{
          enterActive: `${css.opcoesEnter}`,
          enterDone: `${css.opcoesEnterActive}`,
          exit: `${css.opcoesExit}`,
          exitActive: `${css.opcoesExitActive}`,
        }}
      >
        <ModalFaleConnosco
          show={modalFaleConnosco}
          onHide={() => {
            setModalFaleConnosco(false);
            setDisabled(false);
          }}
          allinfo={pontosVenda}
          infoJson={infoJson}
          nodeRef={nodeRef}
        />
      </CSSTransition>

      {ismobile && (
        <>
          <MenuBottom
            infoJson={infoJson}
            isScroll={isScroll}
            addClass={addClass}
            setModalFaleConnosco={setModalFaleConnosco}
            allinfoText={allinfoText}
            modalFaleConnosco={modalFaleConnosco}
            disabled={disabled}
            setDisabled={setDisabled}
          />
        </>
      )}
    </>
  );
}
