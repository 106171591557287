import React, { createContext, useReducer } from "react";

// declare RentContext as a Context object
export const RentContext = createContext();

const initialState = {
  Dias: 0,
  Acessorios: [],
  Preco: 0,
  Devolucao: { local: "", id: "", data: null, foraHoras: false },
  Levantamento: { local: "", id: "", data: null, foraHoras: false },
  condutorIdade: null,
};

// reducer to manage Rent state
const reducer = (prevState, action) => {
  switch (action.type) {
    case "AddDias":
      return {
        ...prevState,
        Dias: action.value,
      };
    case "AddPreco":
      return {
        ...prevState,
        Preco: action.value,
      };
    case "AddAcessorios":
      return {
        ...prevState,
        Acessorios: action.list,
      };

    case "AddDevolucao":
      return {
        ...prevState,
        Devolucao: action.obj,
      };

    case "AddLevantamento":
      return {
        ...prevState,
        Levantamento: action.obj,
      };

    case "AddcondutorIdade":
      return {
        ...prevState,
        condutorIdade: action.value,
      };

    default:
      return initialState;
  }
};

export const RentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <RentContext.Provider value={{ stateRent: state, dispatchRent: dispatch }}>
      {children}
    </RentContext.Provider>
  );
};

export default RentContext;
